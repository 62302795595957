import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
import Seo from "@components/SEO"
import { Section, Title, Paragraph, Column, SmallTitle } from "@components/blocks/page/Section"
import { Table } from "@components/blocks/page/Table"
import { Tags } from "@components/blocks/page/Tags"

import * as css from "@css/components/blocks/page/Content.module.styl"

export default function Company()
{
	return(
		<>
		<Seo/>
        <div className={css.container}>
            <div className={css.inner}>
                <Section>
                    <Title title1='PRIVACY POLICY' title2='' />
                    <Paragraph>
                        <p>
                            株式会社BE-STRONGは、以下の方針に基づき個人情報の保護に努めるものとします。
                        </p>
                    </Paragraph>
                </Section>
                <Section>
                    <SmallTitle title1='法令等の遵守' title2='' />
                    <Paragraph>
                        <p>
                            当社は、個人情報の保護に関する法律、関連する各種法令のガイドラインを遵守し、社会的責任を遂行します。
                        </p>
                    </Paragraph>
                </Section>
                <Section>
                    <SmallTitle title1='個人情報の取得・利用・提供' title2='' />
                    <Paragraph>
                        <p>
                            当社は、事業遂行のために必要な範囲内で利用目的を明確に定め、適切に個人情報の取得、利用及び提供を行います。<br/>
                            取得した個人情報は利用目的の範囲内でのみ利用します。
                        </p>
                    </Paragraph>
                </Section>
                <Section>
                    <SmallTitle title1='個人情報の共同利用及び委託' title2='' />
                    <Paragraph>
                        <p>
                            当社は、個人情報を特定の者との間で共同利用し、又は個人情報の扱いを外部に委託する場合には、共同利用の相手方及び業務委託先に対し、個人情報の適正な利用を実現するため、努力します。
                        </p>
                    </Paragraph>
                </Section>
                <Section>
                    <SmallTitle title1='個人情報の第三者への提供' title2='' />
                    <Paragraph>
                        <p>
                            当社は、法令に規定されている場合を除き、個人情報をあらかじめ本人の同意を得ることなく、第三者には提供しません。
                        </p>
                    </Paragraph>
                </Section>
                <Section>
                    <SmallTitle title1='個人情報の管理' title2='' />
                    <Paragraph>
                        <p>
                            当社は、個人情報の正確性及び最新性を保ち、安全に管理するとともに、個人情報の漏えい、不正なアクセスの防止等個人情報を保護する努力をします。
                        </p>
                    </Paragraph>
                </Section>
                <Section>
                    <SmallTitle title1='個人情報の開示・訂正・利用停止・消去' title2='' />
                    <Paragraph>
                        <p>
                            当社は、本人から個人情報について開示・訂正・利用停止・消去等の要求があった場合には、法令に従い速やかに対応します。 
                        </p>
                    </Paragraph>
                </Section>
            </div>
        </div>
</>
	)
}